* {
  box-sizing: border-box;
  /* font-style: normal; */
  /* font-weight: normal; */
  margin: 0;
  padding: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'CircularStd', sans-serif;
}

::selection {
  background-color: #e19f38;
  color: #fff;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
    visibility: visible;
  }

  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

.fade-active {
  opacity: 1;
}

.fade-exit {
  animation: fadeOut 300ms;
  opacity: 0;
  visibility: hidden;
}

img[class*='align'],
img[class*='wp-image-'] {
  height: auto;
  width: auto;
}

.lazyload,
.lazyloading {
  min-height: 200px;
  min-width: 100%;
  visibility: hidden;
  position: relative;
}

.lazyload::before,
.lazyloading::before {
  /* content: url('/loading.svg'); */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}
 
.blur-up.lazyloaded {
  filter: blur(0);
  visibility: visible;
}

.blur-up.lazyloaded::before {
  /* content: url('/loading.svg'); */
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}